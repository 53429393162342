/*
Script by : Clem
--------------------------------------------
||        AddClass on scroll level        ||
--------------------------------------------

Mettre un attribut detect-scroll-level="" sur une div avec le nombre de pixels de scroll à partir desquels on veut ajouter une class.
Dès que le niveau de scroll sera atteint, la div se verra ajouter la classe "scroll-level-reached"
*/

jQuery(document).ready(function() {
	if(jQuery('[detect-scroll-level]').length) {

		jQuery('[detect-scroll-level]').each(function(i, obj) {
			var current_div = jQuery(this);
		    var required_scroll = jQuery(this).attr('detect-scroll-level');
		    var current_div_classes = jQuery(this).attr('class');

			jQuery(window).scroll(function (event) {

			    var scroll = jQuery(window).scrollTop();

			    if(scroll > required_scroll) {
			    	if(!current_div.hasClass('scroll-level-reached')) {
				    	current_div.addClass('scroll-level-reached');
				    }
			    }

			    if(scroll < required_scroll) {
			    	if(current_div.hasClass('scroll-level-reached')) {
				    	current_div.removeClass('scroll-level-reached');
				    }
			    }
			    

			});
		});

	}
});
