// Activation du swipe mobile sur le carousel
jQuery(document).ready(function() {  

	console.log('Loaded');

	//Enable swiping...
	jQuery("#carousel_accueil").swipe( {
		//Generic swipe handler for all directions
		swipeLeft:function(event, direction, distance, duration, fingerCount) {
			jQuery(this).carousel('next'); 
		},
		swipeRight: function() {
			jQuery(this).carousel('prev'); 
		},
		//Default is 75px, set to 0 for demo so any distance triggers swipe
		threshold:0
	});

});
// Fin Swype