
jQuery(document).ready(function() {
	if(jQuery('.fpf-fields').length) {

		var radios = document.querySelectorAll('.fpf-field.fpf-radio-images .form-row.police input[type="radio"]');

		radios.forEach(function(radio) {
			radio.addEventListener('change', function() {
				var textInput = jQuery('.fpf-fields .form-row.texte input[type="text"]');
				textInput.removeClass('font-lobster font-quacker font-roboto');

				if(this.checked) {
					textInput.addClass('font-'+this.value);
				}
			});
		});

	}
});
