/*
Script by : Clem
-----------------------------------------
||           Bootstrap nav++           ||
-----------------------------------------
*/

navbar_dropdown_on_over = true;

jQuery(document).ready(function() {

	jQuery('.open_language_switcher').click(function (e) { 
		console.log('Clicked language switcher opener');
		if(jQuery('.language-switcher').hasClass('opened')) {
			jQuery('.language-switcher').removeClass('opened');
		} else {
			jQuery('.language-switcher').addClass('opened');
		}
	});

	// Gestion modale recherche
	jQuery('.search_container .bouton_recherche').click(function (e) { 
		jQuery('#modal_recherche').addClass('opened');
		jQuery('#modal_recherche input.search-field').val('');
		jQuery('#modal_recherche input.search-field')[0].focus();
	});
	jQuery('#modal_recherche .close').click(function (e) { 
		jQuery('#modal_recherche').removeClass('opened');
	});

	jQuery('#modal_recherche .loupe').click(function (e) { 
		jQuery('.search-form').submit();
	});

	

	// Ouverture des dropdowns si option true
	if (navbar_dropdown_on_over == true && device_is_desktop) {
		// DROPDOWN NAV SUR HOVER
		jQuery('.nav-item.dropdown').mouseenter(
			function() {
				console.log('Entered dropdown');
				if (typeof mouse_left !== 'undefined') { clearTimeout(mouse_left); }
				if(!jQuery(this).hasClass('show')) {
					jQuery(this).find('[data-toggle=dropdown]').dropdown('toggle');
				}
			}
		);
		jQuery('.nav-item.dropdown').mouseleave(
			function() {
				if(jQuery(this).hasClass('show')) {
					mouse_left = setTimeout(function(){
					jQuery(this).find('[data-toggle=dropdown]').dropdown('toggle');
					jQuery('body').click();
					},300);
				}
			}
		);
	}

});