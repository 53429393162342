
jQuery(function () {

    // Collect product IDs from the page
    var product_ids = [];
    $('.price[data-product_id]').each(function() {
        var product_id = $(this).data('product_id');
        // console.log('product_id:', product_id);
        
        if (product_ids.indexOf(product_id) === -1) {
            product_ids.push(product_id);
        }
    });

    if (product_ids.length > 0) {
        // Send AJAX request to get updated prices
        $.ajax({
            url: my_ajax_object.ajax_url,
            type: 'POST',
            data: {
                action: 'update_prices',
                product_ids: product_ids,
            },
            success: function(response) {
                if (response.success) {
                    var prices = response.data.prices;
                    // console.log('Prices:', prices);
                    
                    // Update price elements on the page
                    for (var product_id in prices) {
                        var $priceElement = $('.price[data-product_id="' + product_id + '"]');
                        // console.log($priceElement, prices[product_id]);
                        $priceElement.html(prices[product_id]);
                        $priceElement.siblings('.price-loader').addClass('hidden');
                    }
                } else {
                    console.error('Error:', response.data.message);
                }
            },
            error: function(xhr, status, error) {
                console.error('AJAX Error:', status, error);
            }
        });
    }
    
});