
jQuery(function(){

	// Déplier filtres au clic sur mobile
	$('.shop_container .sidebar_title').click(function (e) { 
		e.preventDefault();
		
		if($(this).hasClass('opened')) {
			$(this).removeClass('opened');
			$(this).parent().find('.widget').removeClass('opened');
		} else {
			$(this).addClass('opened');
			$(this).parent().find('.widget').addClass('opened');
		}
	});

	jQuery(document).on("woof_ajax_done", woof_ajax_done_handler);
	function woof_ajax_done_handler(e) {
		console.log('WOOF updating.');
		page = '#shop_top';
		var speed = 500;
		$('html, body').animate({ scrollTop: $(page).offset().top }, speed);
	}
});