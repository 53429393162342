
jQuery(document).ready(function () {

    // Add aria-labels to product links
    if(jQuery('.woocommerce-LoopProduct-link').length) {
        console.log('there is a link');
        jQuery('.woocommerce-LoopProduct-link').each(function (index, element) {
            title = jQuery(this).find('h2').html();
            if(title !== undefined) {
                jQuery(this).attr('aria-label', 'Go to product : '+title);
            }
        });
    }

    // Add alts to product thumbnails after 200 ms
    setTimeout(() => { 
        if(jQuery('.woocommerce-product-gallery').length) {
            console.log('there is a gallery');
            jQuery('.woocommerce-product-gallery img').each(function (index, element) {
                file = element.src;
                var n = file.lastIndexOf('/');
                var alt = file.substring(n + 1);
                element.setAttribute('alt', alt);
            });
        }
    }, 200);

});